import { render, staticRenderFns } from "./UiLink.vue?vue&type=template&id=5d46a938&scoped=true"
import script from "./UiLink.vue?vue&type=script&lang=js"
export * from "./UiLink.vue?vue&type=script&lang=js"
import style0 from "./UiLink.vue?vue&type=style&index=0&id=5d46a938&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d46a938",
  null
  
)

export default component.exports