import api, {http} from '@/services/api';

export const getBuildings = async () => {
  const result = await api.get('/buildings');
  return result.data;
};

export const getBuilding = async id => {
  const result = await api.get(`/buildings/${id}`);
  return result.data;
};

export const createBuilding = async payload => {
  const result = await api.post('/buildings', payload);
  return result.data;
};

export const updateBuilding = async payload => {
  const result = await api.patch(`/buildings/${payload.id}`, payload);
  return result.data;
};

export const deleteBuilding = async id => {
  const result = await api.delete(`/buildings/${id}`);
  return result.data;
};

export const getBuildingDepartments = async id => {
  const result = await api.get(`/buildings/${id}/departments`);
  return result.data;
};

export const getBuildingCourses = async id => {
  const result = await api.get(`/courses/building/${id}`);
  return result.data;
};

export const getBuildingUsers = async id => {
  const result = await api.get(`/buildings/${id}/users`);
  return result.data;
};

export const removeBuildingUser = async (buildingId, userId) => {
  const result = await api.delete(`/buildings/${buildingId}/users/${userId}`);
  return result.data;
};

export const getBuildingRole = async () => {
  const result = await api.get(`/buildings/role`);
  return result.data;
};

export const migrate = async () => {
  await api.get(`/utilities/migrate`);
  return null;
};

export const uploadBuildingBackground = async (payload, buildingId) => {
  const fd = new FormData();
  fd.append('image', payload);
  const result = await http.request({
    method: 'post',
    url: '/images/background/building/' + buildingId,
    data: fd,
    headers: { 'content-type': 'multipart/form-data' }
  });
  return result.data;
}

export const getBackgroundPath = async () => {
  const result = await http.request( {
    method: "get",
    url: "images/background",
  });
  return result.data;
}

export const deleteBackground = async (buildingId) => {
  await http.request({
    method: "delete",
    url: "/images/background/building/" + buildingId
  });
}

export const backgroundExists = async (building) => {
  const result = await http.request({
    method: "get",
    url: "/images/background/building/exists/" + building
  });
  return result.data;
}
