<template>
  <span v-if="showComponent">
    <slot></slot>
  </span>
</template>

<script>
import jwtDecode from 'jwt-decode';
import { mapGetters } from 'vuex';

export default {
  props: {
    accessRoles: {
      type: Array,
      required: true
    },
    exact: {
      type: Boolean,
      default: false
    },
    debug: {
      type: String
    }
  },

  data() {
    return {
      userRoles: []
    };
  },

  computed: {
    ...mapGetters('oidcStore', ['oidcAccessToken']),

    showComponent() {
      const show = this.hasRole();
      return show;
    }
  },

  methods: {
    hasRole() {
      let access = false;
      this.userRoles.forEach(ur => {
        this.accessRoles.forEach(ar => {
          if (ur === ar && !access) {
            access = true;
          }
        });
      });
      if (this.debug) {
         console.debug(this.debug, access, this.userRoles, this.accessRoles);
      }
      return access;
    },

    setUserRoles() {
      const roles = jwtDecode(this.oidcAccessToken).role;
      if (typeof roles !== 'string') {
        roles.map(r => this.userRoles.push(r));
      } else {
        this.userRoles.push(roles);
      }
    }
  },

  created() {
    this.setUserRoles();
  }
};
</script>
