<template>
  <el-select
    v-model="localValue"
    :placeholder="`${$t('actions.select')} ${$tc('models.building')}`"
    :loading="loading"
    filterable
    value-key="id"
    @change="handleSelect"
  >
    <el-option
      v-for="building in buildings"
      :key="building.id"
      :label="building.name"
      :value="building"
    >
    </el-option>
  </el-select>
</template>

<script>
import { getBuildings } from '@/app/buildings/api';

export default {
  props: {
    value: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      localValue: this.value,
      buildings: [],
      loading: false
    };
  },

  watch: {
    value(value) {
      this.localValue = value;
    }
  },

  methods: {
    handleSelect() {
      this.$emit('input', this.localValue);
    },

    async getBuildings() {
      this.loading = true;
      this.buildings = await getBuildings();
      this.loading = false;
    }
  },

  created() {
    this.getBuildings();
  }
};
</script>
