import { render, staticRenderFns } from "./UiCard.vue?vue&type=template&id=a77ea89e&scoped=true"
import script from "./UiCard.vue?vue&type=script&lang=js"
export * from "./UiCard.vue?vue&type=script&lang=js"
import style0 from "./UiCard.vue?vue&type=style&index=0&id=a77ea89e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a77ea89e",
  null
  
)

export default component.exports