<template>
  <div>
    <auth-checker :access-roles="['SuperAdmin', 'CompanyAdmin']">
      <el-select
          v-model="localValue"
          :placeholder="`${$t('actions.select')} ${$tc('models.company')}`"
          :loading="loading"
          filterable
          value-key="id"
          @change="handleSelect"
      >
        <el-option
            v-for="company in companies"
            :key="company.id"
            :label="company.name"
            :value="company"
        >
        </el-option>
      </el-select>
    </auth-checker>
    <auth-checker :access-roles="['BuildingAdmin']">
      <el-select
          v-model="localValue"
          :placeholder="`${$t('actions.select')} ${$tc('models.company')}`"
          :loading="loading"
          filterable
          disabled
          value-key="id"
      >
        <el-option
            v-for="company in companies"
            :key="company.id"
            :label="company.name"
            :value="company"
        >
        </el-option>
      </el-select>
    </auth-checker>
  </div>
</template>

<script>
import { getCompanies } from '@/app/companies/api';
import AuthChecker from "@/app/auth/components/AuthChecker";

export default {
  components: {AuthChecker},
  props: {
    value: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      localValue: this.value,
      companies: [],
      loading: false
    };
  },

  watch: {
    value(value) {
      this.localValue = value;
    }
  },

  methods: {
    handleSelect() {
      this.$emit('input', this.localValue);
    },

    async getCompanies() {
      this.loading = true;
      const companies = await getCompanies();
      this.companies = companies;
      this.loading = false;
    }
  },

  created() {
    this.getCompanies();
  }
};
</script>
