<template>
  <div :class="margin">
    <label
      class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
    >
      {{ label }}
      <span v-if="isRequired">*</span>
    </label>
    <validation-provider :name="label" :rules="rules" v-slot="{ errors }">
      <slot></slot>
      <span class="block text-sm mt-2 text-red-500">{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>

<script>
import { contains, split } from 'ramda';

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    rules: {
      type: String
    },
    minimize: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    isRequired() {
      return this.rules && contains('required', split('|', this.rules));
    },

    margin() {
      return this.minimize ? 'mb-3' : 'mb-8';
    }
  }
};
</script>
