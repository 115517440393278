<template>
  <el-select
    v-model="localValue"
    :placeholder="`${$t('actions.select')} ${$tc('models.role')}`"
    :loading="loading"
    value-key="id"
    @change="handleSelect"
  >
    <el-option
      v-for="role in roles"
      :key="role.id"
      :label="$t(`roles.${role.name}`)"
      :value="role"
    >
    </el-option>
  </el-select>
</template>

<script>
import { getRoles } from '@/app/roles/api';

export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    restricted: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      localValue: this.value,
      roles: [],
      loading: false
    };
  },

  watch: {
    value(value) {
      this.localValue = value;
    }
  },

  methods: {
    handleSelect() {
      this.$emit('input', this.localValue);
    },

    async getRoles() {
      this.loading = true;
      this.roles = await getRoles(this._props.restricted);
      this.loading = false;
    }
  },

  created() {
    this.getRoles();
  }
};
</script>
