import { render, staticRenderFns } from "./UiTriangleUp.vue?vue&type=template&id=ce604b1c&scoped=true"
import script from "./UiTriangleUp.vue?vue&type=script&lang=js"
export * from "./UiTriangleUp.vue?vue&type=script&lang=js"
import style0 from "./UiTriangleUp.vue?vue&type=style&index=0&id=ce604b1c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce604b1c",
  null
  
)

export default component.exports