<template>
  <ui-card
    v-loading="loading"
    :heading="`${$t('actions.create')} ${$tc('models.user')}`"
  >
    <user-form
      :user="user"
      @submit="handleSubmit"
      @cancel="handleCancel"
    ></user-form>
  </ui-card>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import UserForm from '../components/UserForm';
import { createUser } from '../api';

export default {
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: null,
        company: null,
        building: null,
        department: null
      },
      loading: false
    };
  },

  components: {
    UiCard,
    UserForm
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      await createUser(this.user);
      this.$router.back();
      this.loading = false;
    },

    handleCancel() {
      this.$router.back();
    }
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.users", link: "/app/users/"},
      {name: "actions.create", link: "/app/users/create"}
    ]);
  },
};
</script>
