<template>
  <router-link v-if="route" :class="styles" :to="route">
    <slot></slot>
  </router-link>

  <a v-else :class="styles" href="#" @click.prevent="handleClick">
    <slot></slot>
  </a>
</template>

<script>
import { concat, reduce } from 'ramda';

export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'base'
    },
    route: {
      type: [String, Object]
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    styles() {
      const baseStyles = [
        'button-transition',
        'inline-block',
        'font-bold',
        'rounded'
      ];

      const sizeStyles = {
        small: ['text-xs', 'px-4', 'py-2'],
        base: ['text-sm', 'px-4', 'py-2'],
        large: ['text-base', 'px-6', 'py-3']
      };

      const typeStyles = this.disabled
        ? {
          default: [],
          primary: [],
          success: [],
          danger: [],
          text: []
        }
        : {
          default: ['text-white', 'bg-gray-500', 'hover:bg-gray-600'],
          primary: ['text-black', 'bg-green-500', 'hover:bg-green-700'],
          success: ['text-black', 'bg-green-500', 'hover:bg-green-700'],
          danger: ['text-white', 'bg-red-500', 'hover:bg-red-700'],
          normal: ['text-black, ', 'bg-gray-200', 'hover:bg-gray-400'],
          secondary: ['text-black', 'bg-white', 'hover:bg-gray-300'],
          text: ['text-gray-500', 'hover:bg-white', 'hover:text-gray-600']
        };

      const disabledStyles = this.disabled
        ? [
          'bg-gray-300',
          'hover:bg-gray-300',
          'text-gray-600',
          'cursor-default'
        ]
        : [];

      const uppercaseStyles = this.uppercase ? ['uppercase'] : [];

      return reduce(
        concat,
        [],
        [
          baseStyles,
          sizeStyles[this.size],
          typeStyles[this.type],
          disabledStyles,
          uppercaseStyles
        ]
      );
    }
  },

  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button-transition {
  transition: all 0.25s;
}
</style>
