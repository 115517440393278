<template>
  <div class="bg-white rounded overflow-hidden">
    
    <div class="uicontainer">
      <div v-if="heading" class="p-4 md:p-6 font-bold uppercase">
        {{ heading }}
      </div>
      <div v-if="$slots.hright" class="p-4 md:p-6 hright-slot">
        <slot name="hright"></slot>
      </div>
    </div>

    <div class="p-4 md:p-6">
      <slot></slot>
    </div>

    <div v-if="$slots.footer" class="p-4 md:p-6 bg-gray-100">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String
    }
  }
};
</script>

<style scoped>
  .uicontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .uicontainer > * {
    flex-basis: auto;
  }

  .hright-slot > * {
    margin: auto 8px;
  }
</style>